import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import DemoLogin from '../views/DemoLogin'
import Html from '../views/Html1.vue'
import Success from '../views/success.vue'
import Error from '../views/error.vue'
import loading from '../views/loading.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/DemoLogin',
    name: 'DemoLogin',
    component: DemoLogin
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/Success',
    name: 'Success',
    component: Success
  },
  {
    path: '/Error',
    name: 'Error',
    component: Error
  },
  {
    path: '/loading',
    name: 'loading',
    component: loading
  },
  {
    path: '/Html',
    name: 'Html',
    component: Html
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
