<template>
  <div class="login" v-loading="loading">
    <!-- <img src="../assets/loginBG.jpg" alt="" /> -->
    <div class="loginForm">
      <h1 style="color: #000000; ">HI~</h1>
      <h2 style=" color: #000000;font-family: 'Times New Roman', Georgia, Serif ">
        欢迎体验 "码上签"
      </h2>
      <div class="box">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="发货方登录" name="second">
            <el-form label-width="80px" :model="ruleForm1" :rules="rules1" ref="ruleForm1" style="margin-top: 57px">
              <el-form-item label="手机号:" prop="user">
                <el-input v-model="ruleForm1.user" placeholder="登录账号" @keyup.enter.native="CustomerSubmit"></el-input>
              </el-form-item>
              <el-form-item label="密码:" prop="password">
                <el-input v-model="ruleForm1.password" placeholder="密码" type="password"
                  @keyup.enter.native="CustomerSubmit"></el-input>
              </el-form-item>
              <div class="buttonBlock">
                <span class="errMsg" v-show="errMsg">{{ errMsg }}</span>
                <el-button class="formBtn" round @click="CustomerSubmit">登 录</el-button>
              </div>
              <!-- <div class="Abtn"><a @click="ForgetPassword">忘记密码?</a></div> -->
              <div class="botxet">
                <p> 体验账号 :18600000000 &nbsp;&nbsp; 密码:123456</p>
              </div>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="签收方登录" name="first">
            <el-form label-width="80px" :model="ruleForm" :rules="rules" ref="ruleForm" style="margin-top: 57px">
              <el-form-item label="手机号:" prop="user">
                <el-input v-model="ruleForm.user" placeholder="登录账号" @keyup.enter.native="submit"></el-input>
              </el-form-item>
              <el-form-item label="密码:" prop="password">
                <el-input v-model="ruleForm.password" placeholder="密码" @keyup.enter.native="submit"
                  type="password"></el-input>
              </el-form-item>
              <div class="buttonBlock">
                <span class="errMsg" v-show="errMsg">{{ errMsg }}</span>
                <el-button class="formBtn" round @click="submit">登 录</el-button>
              </div>
              <!-- <div class="Abtn"><a @click="ForgetPassword">忘记密码?</a></div> -->
              <div class="botxet">
                <p> 体验账号 :18600000000 &nbsp;&nbsp; 密码:123456</p>
              </div>
            </el-form>
          </el-tab-pane>

        </el-tabs>
      </div>
    </div>

  </div>
</template>

<script>
import base from '@/utils/base64' //引入
const regExp = new RegExp('18600000000') // 手机号正则
export default {
  data() {
    // var validatePass = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请再次输入密码'))
    //   } else if (value !== this.ForgetPasswordForm.NewPwd) {
    //     callback(new Error('两次输入密码不一致!'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      ForgetVisible: false,
      activeName: 'first',
      ruleForm: {},
      ruleForm1: {},
      // ForgetPasswordForm: {},
      rules: {
        user: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入体验账号'))
              } else if (!regExp.test(value)) {
                callback(new Error('请输入正确的体验账号'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
      },
      rules1: {
        user: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入体验账号'))
              } else if (!regExp.test(value)) {
                callback(new Error('请输入正确的体验账号'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },

      timer: null,
      oneMinute: 60,
      disabled: false,
      loading: false,
      base: new base(),
      errMsg: '',
    }
  },
  mounted() {
    if (this.$route.query.tap) {
      this.activeName = 'first'
    } else {
      this.activeName = 'second'
    }
  },
  methods: {
    handleClick() {
      this.errMsg = ''
      if (this.$refs['ruleForm']) this.$refs['ruleForm'].clearValidate()
      if (this.$refs['ruleForm1']) this.$refs['ruleForm1'].clearValidate()
    },
    submit() {
      this.errMsg = ''
      this.$refs['ruleForm'].validate((valid) => {
        if (!valid) {
          return
        }
        this.loading = true
        this.$http
          .post('/BO/CustomerLinkman/CustomerLinkmanLogin', {
            SignInPhone: this.ruleForm.user,
            SignInSmsCode: this.ruleForm.password,
          })
          .then((res) => {
            this.loading = false
            if (res.Success) {
              this.$router.replace('/home')
              sessionStorage.setItem('userInfo', JSON.stringify(res.Data))
              sessionStorage.setItem('Demologin','1')
            } else {
              this.errMsg = res.Msg
            }
          })
      })
    },
    CustomerSubmit() {
      this.errMsg = ''
      this.$refs['ruleForm1'].validate((valid) => {
        if (!valid) {
          return
        }
        this.loading = true
        this.$http
          .post('/BO/CustomerLinkman/BserUserLogin', {
            SignInPhone: this.ruleForm1.user,
            SignInSmsCode: this.ruleForm1.password,
          })
          .then((res) => {
            this.loading = false
            if (res.Success) {
              let str = this.base.encode(this.ruleForm1.user) //加密
              window.open(
                ' http://hzq.yoojet.com/#/Login/LoginPage?c=' + str +'&d=1',
                '_self'
              )
            } else {
              this.errMsg = res.Msg
            }
          })
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.el-tabs__item {
  width: 235px;
  text-align: center;
  border-radius: 10px;
  font-size: 18px;
  line-height: 65px;
  height: 70px;
  font-weight: bold;
}

/deep/.el-tabs__item:hover {
  color: #d50700;
}

/deep/.el-tabs__item.is-active {
  color: #d50700;
}

/deep/.el-tabs__active-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: #d50700;
  z-index: 1;
  transition: transform .3s cubic-bezier(.645, .045, .355, 1);
  list-style: none;
}

.login {
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 1920px;
  max-height: 1080px;
  background-image: url('../assets/bg2.png');
  background-repeat: no-repeat;
  background-size: cover;

  .formLabel {
    display: flex;
  }

  .formInput {
    margin: 7px 0;

    /deep/.el-input__inner {
      border-radius: 40px;
      // border-color: #888;
    }
  }

  .loginForm {
    width: 400px;
    height: 600px;
    margin-top: 7.2%;
    margin-left: 34.5%;

    .buttonBlock {
      position: relative;
      margin-top: 40px;
      text-align: center;

      .errMsg {
        position: absolute;
        top: -20px;
        left: 80px;
        color: #f56c6c;
        font-size: 12px;
      }
    }

    .Abtn a {
      color: #d50700;
      float: right;
    }

    .formBtn {
      font-weight: bold;
      font-size: 18px;
      width: 60%;
      display: inline-block;
      justify-content: center;
      margin: 0 auto;
      color: #fff;
      background-color: #d50700;
      border-color: #d50700;
    }

    .formBtn:hover {
      color: #fff;
      background-color: #d1110bf3;
      border-color: #d1110bf3;
    }
  }
}

.box {
  padding: 10px;
  background-color: white;
  box-shadow: 0px 5px 40px#8c8c8c60;
  margin-top: 50px;
  width: 470px;
  height: 390px;
  border-radius: 20px;
}

#option {
  border-radius: 20px;
  margin-bottom: 40px;
  width: 92%;
  height: 88px;
  line-height: 40px;
  border-radius: 20px;
  margin-bottom: 40px;
}

.botxet {
  color: #909090;
  margin: 33px 0px 0px 87px;
}</style>
